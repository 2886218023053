import React from 'react'
import { Button, Segment } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'

class TextShowMore extends React.Component {
  state = {
    open: false
  }

  handleShow = () => {
    this.setState({ open: !this.state.open })
  }

  render () {
    const { text, t } = this.props
    const { open } = this.state

    const showButton = text.length > 750

    return (
      <span className={`text-show-more ${!showButton || open ? 'open' : ''}`}>
        <div
          className='text-wrapper'
          dangerouslySetInnerHTML={{
            __html: text
          }}
        />

        {showButton && (
          <Segment basic vertical textAlign='center'>
            <Button
              size='small'
              color='red'
              icon
              labelPosition='right'
              inverted
              onClick={this.handleShow}
            >
              {open ? t('text-show-more.less') : t('text-show-more.more')}
              <i className={`icon ta-arrow-2-${open ? 'up' : 'down'}`} />
            </Button>
          </Segment>
        )}
      </span>
    )
  }
}

export default withTranslation()(TextShowMore)
