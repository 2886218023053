import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Header, Divider, Card, Button, Segment, List, Icon, Grid } from 'semantic-ui-react'
import { filtersActions } from '../Filters/state'
import { withTranslation } from 'react-i18next'

import ItemCard from '../Items/ItemCard'

const ITEM_LIMIT = 10

class ItemList extends Component {
  state = { limit: 4 }

  handleLoadMore = () => {
    this.setState(prevState => ({ limit: prevState.limit + ITEM_LIMIT * 2 }))
  }

  render () {
    const { collection, items, sortOrder, setOrder, t } = this.props
    const { limit } = this.state

    const total = items.length

    // limit total visible items
    const slicedItems = items.slice(0, limit)

    return (
      <Segment vertical basic padded>
        {/* ITEM TOOLBOX WITH TOTAL FOUND AND SORTERS */}
        <Header as='h2' color='red' dividing style={{ marginBottom: 5 }}>{collection}</Header>
        <Grid>
          <Grid.Row only='tablet computer'>
            <Grid.Column width={4}>
              {items.length} {t('item-list.found')}
            </Grid.Column>
            <Grid.Column width={12} textAlign='right'>
              <List divided horizontal verticalAlign='top'>
                <List.Item>{t('item-list.sort')}</List.Item>
                <List.Item as='a'><span onClick={() => setOrder('title')} className={`${sortOrder === 'title' ? 'active' : ''}`}>{t('item-list.name')}</span></List.Item>
                <List.Item as='a'><span onClick={() => setOrder('region')} className={`${sortOrder === 'region' ? 'active' : ''}`}>{t('item-list.region')}</span></List.Item>
                <List.Item as='a'><span onClick={() => setOrder('location')} className={`${sortOrder === 'location' ? 'active' : ''}`}>{t('item-list.place')}</span></List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider clearing hidden />

        <Card.Group itemsPerRow={2} doubling>
          {slicedItems.map(({ node }) => <ItemCard key={node.id} node={node} />)}
        </Card.Group>

        <Divider clearing hidden />

        {limit < total &&
          <Segment basic textAlign='center'>
            <Button onClick={this.handleLoadMore} icon labelPosition='right' color='red' inverted>
              {t('item-list.more')}
              <Icon name='plus' />
            </Button>
          </Segment>
        }
      </Segment>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ filters }) => {
  return { sortOrder: filters.sortOrder }
}

const mapDispatchTopProps = {
  setOrder: filtersActions.setOrder
}

export default withTranslation()(connect(mapStateToProps, mapDispatchTopProps)(ItemList))
