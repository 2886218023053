import React from 'react'
import { connect } from 'react-redux'
import { Header, Segment, Popup } from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next'
import { Link } from '../../locales'

// import TextLong from '../Common/TextLong'
import TextShowMore from '../Common/TextShowMore'
import ItemList from './ItemList'
import { filtersActions } from '../Filters/state'
import { unescape } from '../../utils/helpers'

const ItemGroups = ({
  found,
  hotels,
  tours,
  trips,
  dataObject,
  dataObjectType,
  isCountry,
  service,
  t,
  i18n
}) => {
  let seoFields = {}
  if (dataObject) {
    seoFields =
      dataObjectType === 'country' && service === 'all-services'
        ? dataObject.seo.filter
        : dataObject.seo
  }

  return (
    <div>
      {found.total ? (
        <div>
          {dataObject ? (
            <Segment basic vertical padded>
              <Header
                as='h1'
                color='red'
                textAlign='center'
                style={{ fontSize: 30 }}
              >
                {service === 'all-services'
                  ? seoFields.h1
                  : seoFields[service].h1}
                {isCountry && (
                  <Popup
                    trigger={
                      <span>
                        <Link
                          as='button'
                          lang={i18n.language}
                          to={`/countries/${dataObject.slug}`}
                        >
                          <i
                            className='icon ta-info-2'
                            aria-label={dataObject.title}
                          />
                        </Link>
                      </span>
                    }
                    content={t('nav-main.land-info')}
                    position='bottom center'
                    horizontalOffset={5}
                  />
                )}
              </Header>

              {/* <TextLong text={service === 'all-services' ? seoFields.text : seoFields[service].text} line={3} /> */}
              <TextShowMore
                text={
                  service === 'all-services'
                    ? unescape(seoFields.text)
                    : unescape(seoFields[service].text)
                }
              />
            </Segment>
          ) : (
            <Segment basic vertical padded textAlign='center'>
              <Trans i18nKey='item-groups.header.block1'>
                <Header as='h2'>
                  Ihre Traumferien –<br />
                  <span>von uns bereits getestet</span>
                </Header>
              </Trans>
              <Header as='h3'>{t('item-groups.header.block2')}</Header>
              <p>
                {t('item-groups.header.block3')}
                <br />
                <span>{t('item-groups.header.block4')}</span>
              </p>
            </Segment>
          )}

          {found.tours > 0 && (
            <ItemList
              collection={t('item-groups.collections.tours')}
              items={tours}
            />
          )}
          {found.hotels > 0 && (
            <ItemList
              collection={t('item-groups.collections.hotels')}
              items={hotels}
            />
          )}
          {found.trips > 0 && (
            <ItemList
              collection={t('item-groups.collections.trips')}
              items={trips}
            />
          )}
        </div>
      ) : (
        <Segment basic vertical padded textAlign='center'>
          <Trans i18nKey='item-groups.body'>
            <Header as='h2'>
              Äxgüsi – leider haben wir
              <br />
              <span>kein passendes Angebot gefunden.</span>
            </Header>
            <Header as='h3'>
              Wir bieten Ihnen eine selektive Auswahl der besten Unterkünfte.
            </Header>
            <p>
              Bitte versuchen Sie es mit anderen Filtern erneut oder
              kontaktieren Sie unsere Asien-Spezialisten für eine
              <br />
              <b>individuelle Beratung: 043 233 30 90.</b>
            </p>
          </Trans>
        </Segment>
      )}
    </div>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({ filters: { result } }) => {
  return {
    found: result.found,
    hotels: result.hotels,
    tours: result.tours,
    trips: result.trips
  }
}

const mapDispatchTopProps = {
  setFilters: filtersActions.setFilters
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchTopProps)(ItemGroups)
)
