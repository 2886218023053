import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import {
  Button,
  Divider,
  Dropdown,
  Header,
  Segment,
  Responsive,
  Input,
  Grid,
  Icon
} from 'semantic-ui-react'
import { Range } from 'rc-slider'
import { withTranslation, Trans } from 'react-i18next'

import XDropdown from './XDropdown'
import { ButtonIcon, ButtonIconGroup } from '../ButtonIcon'
import {
  getSharedCategories,
  getItemsCategories,
  localizeLink
} from '../../utils/helpers'
import {
  getCountryOptions,
  getRegionOptions,
  getLocationOptions
} from '../../utils/options'
import { computerBreakpoint, links } from '../../utils/constants'

import { filtersActions } from './state'
import { navigationActions } from '../Navigations/state'

const SiderFilters = ({
  i18n,
  setFilters,
  setService,
  resetFilters,
  options,
  options: {
    service,
    country,
    region,
    location,
    stars,
    duration,
    sharedCategories,
    hotelsCategories,
    toursCategories,
    tripsCategories,
    serviceGroup
  },
  init,
  result,
  place,
  finderOptions,
  mobileFiltersActive,
  toggleMobileFilters,
  hotels,
  trips,
  tours,
  t
}) => {

  const [searchCodeDest, setSearchCodeDest] = useState('')
  const [searchCodeLoc, setSearchCodeLoc] = useState('')
  const [searchError, setSearchError] = useState('')

  const handleResetFilters = () => {
    resetFilters()
    setFilters({ country, region, location })

    const { pathname } = window.location
    const pathParts = pathname.split('/')

    if (serviceGroup) {
      if (region) {
        const translatedAllServices = links['all-services'][i18n.language]
        pathParts[4] = translatedAllServices
      } else {
        pathParts[4] = null
      }
    }

    return navigate(pathParts.join('/'))
  }

  const handleFinderChange = (e, { value, clear }) => {
    const {
      country,
      region,
      location,
      countrySlug,
      regionSlug,
      locationSlug
    } = value ? JSON.parse(value) : {}
    setFilters({ country, region, location })

    let link = `/${i18n.language}/destinations/`
    // if clearing the select element, use URL without country slug, add the slug otherwise
    if (!clear) {
      link += `${countrySlug || ''}/`
    } else {
      link += 'all'
    }

    if (regionSlug) {
      link += `/${serviceGroup || 'all-services'}/regions/${regionSlug}/`
      if (locationSlug) {
        link += `/locations/${locationSlug}/`
      }
    } else {
      link += `/${serviceGroup || ''}`
    }

    return navigate(localizeLink(link, i18n.language))
  }

  const handleRegionChange = (e, { value, clear }) => {
    const { country, region, location, countrySlug, regionSlug } = value
      ? JSON.parse(value)
      : {}
    setFilters({ country, region, location })

    let link = `/${i18n.language}/destinations/${countrySlug}`
    if (!clear) {
      link += `/${serviceGroup || 'all-services'}/regions/${regionSlug || ''}`
    } else {
      link += `/${serviceGroup || ''}`
    }

    return navigate(localizeLink(link, i18n.language))
  }

  const handleLocationChange = (e, { value, clear }) => {
    const {
      country,
      region,
      location,
      countrySlug,
      regionSlug,
      locationSlug
    } = value ? JSON.parse(value) : {}
    setFilters({ country, region, location })

    let link = `/${i18n.language}/destinations/${countrySlug}/${serviceGroup ||
      'all-services'}/regions/${regionSlug}/`
    if (!clear) {
      link += `locations/${locationSlug || ''}`
    }

    return navigate(localizeLink(link, i18n.language))
  }

  const handleServiceToggle = service => {
    // if we are or region/location page, we still need the 'all-services' string in URL
    let newServiceGroup = region ? 'all-services' : ''

    if (options.service !== service) {
      newServiceGroup = service
    }

    const { pathname } = window.location
    const currentServiceGroup = pathname.split('/')[4]

    let link = ''
    if (currentServiceGroup) {
      link = pathname.replace(currentServiceGroup, newServiceGroup)
    } else {
      link = `${pathname}/${newServiceGroup}`
    }

    navigate(localizeLink(link, i18n.language))

    return setService({ service: options.service === service ? '' : service })
  }

  const handleCategoryToggle = ({ category, service }) => {
    const categories = options[`${service}Categories`]
    const index = categories.indexOf(category)

    if (index > -1) {
      categories.splice(index, 1)
    } else {
      categories.push(category)
    }

    return setFilters({ [`${service}Categories`]: categories })
  }

  const handleDurationSet = (min, max) => {
    return setFilters({ duration: { min, max } })
  }

  const handleStarsChange = starNumber => {
    return setFilters({ stars: starNumber === stars ? 0 : starNumber })
  }

  const starsFiller = starNumber => {
    let stars = []

    for (let i = 1; i <= 5; i++) {
      if (serviceGroup && serviceGroup !== 'hotels') {
        stars.push(
          <i
            key={i}
            className='icon link big gray ta-hotel-t-outline'
            style={{ cursor: 'not-allowed' }}
          />
        )
      } else if (i <= starNumber) {
        stars.push(
          <i
            key={i}
            className='icon link big red ta-hotel-t-outline'
            onClick={() => handleStarsChange(i)}
          />
        )
      } else {
        stars.push(
          <i
            key={i}
            className='icon link big gray-second ta-hotel-t-outline'
            onClick={() => handleStarsChange(i)}
          />
        )
      }
    }

    return stars
  }

  const handleDestCodeChange = e => {
    setSearchError('')
    setSearchCodeDest(e.target.value)
  }

  const handleLocCodeChange = e => {
    setSearchError('')
    setSearchCodeLoc(e.target.value)
  }

  const handleCodeNavigation = () => {
    const code = searchCodeDest.trim() + searchCodeLoc.trim()

    if (code.length < 8) {
      setSearchError(t('finder.code-search.error.short'))
      return
    }

    if (code.length > 9) {
      setSearchError(t('finder.code-search.error.long'))
      return
    }

    const found = [...hotels, ...trips, ...tours].filter(item => item.node.mergedCode.toLowerCase() === code.toLowerCase())

    if (!found.length) {
      setSearchError(t('finder.code-search.error.wrong'))
      return
    }

    const node = found[0].node
    const url = `/${node.lang}/${links.destinations[node.lang]}/${node.countrySlug}/${links[node.collection][node.lang]
      }/${node.slug}`

    return navigate(url)
  }

  const countryOptions = getCountryOptions(result.countries)
  const regionOptions = getRegionOptions(result.regions)
  const locationOptions = getLocationOptions(result.locations)

  const countryValue = country
    ? countryOptions.find(({ text }) => text === country)
    : null
  const regionValue = region
    ? regionOptions.find(({ text }) => text === region)
    : null
  const locationValue = location
    ? locationOptions.find(({ text }) => text === location)
    : null

  const allSharedCategories = getSharedCategories(init.allCategories)
  const allHotelsCategories = getItemsCategories(init.allCategories, 'hotels')
  const allTripsCategories = getItemsCategories(init.allCategories, 'trips')
  const allToursArtenCategories = init.allCategories
    .filter(({ node }) => node.type === 'Arten' && node.service === 'tours')
    .map(({ node: { title } }) => title)
  const allToursKategorienCategories = init.allCategories
    .filter(
      ({ node }) => node.type === 'Kategorien' && node.service === 'tours'
    )
    .map(({ node: { title } }) => title)

  const starsRange = stars
    ? `${Number(stars)} und ${Number(stars) + 0.5}`
    : t('sider-filters.all')

  if (!duration.min) {
    duration.min = 1
  }
  if (!duration.max) {
    duration.max = 18
  }
  const durMinLabel = String(duration.min)
  const durMaxLabel = String(duration.max)

  const usingFilters =
    (typeof window !== 'undefined' && window.location.search) || serviceGroup

  return (
    <div>
      <Grid>
        <Grid.Column only='mobile tablet'>
          <Segment basic vertical>
            <Dropdown
              fluid
              search
              selection
              minCharacters={3}
              icon='search'
              placeholder={t('sider-filters.destination')}
              options={finderOptions}
              className='inverted'
              onChange={handleFinderChange}
              aria-label='search'
            />

            <ButtonIconGroup noWrap>
              <ButtonIcon
                title={t('finder.tour')}
                icon='ta-roundtrip'
                active={service === 'tours'}
                onClick={() => handleServiceToggle('tours')}
                disabled={!place.found.tours}
                inline
              />
              <ButtonIcon
                title={t('finder.hotel')}
                icon='ta-hotel'
                active={service === 'hotels'}
                onClick={() => handleServiceToggle('hotels')}
                disabled={!place.found.hotels}
                inline
              />
              <ButtonIcon
                title={t('finder.trip')}
                icon='ta-excursion'
                active={service === 'trips'}
                onClick={() => handleServiceToggle('trips')}
                disabled={!place.found.trips}
                inline
              />
            </ButtonIconGroup>

            <Button fluid onClick={toggleMobileFilters}>
              <i className='icon options' />
              {t('sider-filters.filters')}
            </Button>
          </Segment>
          <div
            className='ui stackable four large buttons filter-search code-search-wrap'
            aria-label={t('finder.what')}
          >
            <h4 className='code-search-heading'>
              {t('finder.code-search.heading')}
            </h4>
            <p className='code-search-text'>
              {t('finder.code-search.sub-text')}
            </p>
            <input
              inverted
              size='big'
              className='code-search-dest'
              placeholder={t('finder.code-search.placeholder.dest')}
              onChange={handleDestCodeChange}
              value={searchCodeDest}
            />
            <input
              inverted
              size='big'
              className='code-search-loc'
              placeholder={t('finder.code-search.placeholder.loc')}
              onChange={handleLocCodeChange}
              value={searchCodeLoc}
            />
            <Button
              onClick={() => handleCodeNavigation()}
              icon
              inverted
            >
              <span>
                {t('finder.code-search.button')}
              </span>
            </Button>
            <p className={`code-search-error ${searchError ? 'show' : ''}`}>
              {searchError}
            </p>
          </div>
        </Grid.Column>
      </Grid>

      <div
        className={`sider-filters ${mobileFiltersActive ? 'mobile-show' : 'mobile-hide'
          }`}
      >
        <div className='filters-group'>
          <Segment basic vertical padded='very'>
            <Header as='h2' dividing>
              {t('sider-filters.search')}
            </Header>

            {usingFilters && (
              <div>
                <ButtonIcon
                  title={
                    <span>
                      <Icon
                        className='ta-close'
                        color='red'
                        style={{ top: 2, position: 'relative' }}
                      />{' '}
                      {t('sider-filters.reset')}
                    </span>
                  }
                  onClick={handleResetFilters}
                />
                <Divider hidden />
              </div>
            )}

            <Grid>
              <Grid.Column only='computer'>
                <Header as='h3'>{t('sider-filters.what')}</Header>
                {/* --- SERVICE */}
                <ButtonIconGroup>
                  <ButtonIcon
                    title={t('finder.tour')}
                    icon='ta-roundtrip'
                    active={service === 'tours'}
                    onClick={() => handleServiceToggle('tours')}
                    disabled={!place.found.tours}
                    square
                  />
                  <ButtonIcon
                    title={t('finder.hotel')}
                    icon='ta-hotel'
                    active={service === 'hotels'}
                    onClick={() => handleServiceToggle('hotels')}
                    disabled={!place.found.hotels}
                    square
                  />
                  <ButtonIcon
                    title={t('finder.trip')}
                    icon='ta-excursion'
                    active={service === 'trips'}
                    onClick={() => handleServiceToggle('trips')}
                    disabled={!place.found.trips}
                    square
                  />
                </ButtonIconGroup>

                <Header as='h3'>{t('sider-filters.where')}</Header>
                <Input fluid size='large' style={{ marginBottom: 5 }}>
                  <Dropdown
                    fluid
                    search
                    selection
                    minCharacters={3}
                    icon='search'
                    placeholder={t('sider-filters.destination')}
                    options={finderOptions}
                    className='inverted'
                    onChange={handleFinderChange}
                    style={{ border: 0, borderBottom: '1px solid #ccc' }}
                  />
                </Input>
                {/* --- COUNTRY */}
                <XDropdown
                  selectedOption={countryValue}
                  options={countryOptions}
                  placeholder={t('sider-filters.countries')}
                  onChange={handleFinderChange}
                />
                {/* --- REGION */}
                <XDropdown
                  selectedOption={regionValue}
                  options={regionOptions}
                  disabled={!country}
                  placeholder={t('sider-filters.regions')}
                  onChange={handleRegionChange}
                />
                {/* --- LOCATION */}
                <XDropdown
                  selectedOption={locationValue}
                  options={locationOptions}
                  disabled={!country}
                  placeholder={t('sider-filters.locations')}
                  onChange={handleLocationChange}
                />
                <div
                  className='ui stackable four large buttons filter-search code-search-wrap'
                  aria-label={t('finder.what')}
                >
                  <h4 className='code-search-heading'>
                    {t('finder.code-search.heading')}
                  </h4>
                  <p className='code-search-text'>
                    {t('finder.code-search.sub-text')}
                  </p>
                  <input
                    inverted
                    size='big'
                    className='code-search-dest'
                    placeholder={t('finder.code-search.placeholder.dest')}
                    onChange={handleDestCodeChange}
                    value={searchCodeDest}
                  />
                  <input
                    inverted
                    size='big'
                    className='code-search-loc'
                    placeholder={t('finder.code-search.placeholder.loc')}
                    onChange={handleLocCodeChange}
                    value={searchCodeLoc}
                  />
                  <Button
                    onClick={() => handleCodeNavigation()}
                    icon
                    inverted
                  >
                    <span>
                      {t('finder.code-search.button')}
                    </span>
                  </Button>
                  <p className={`code-search-error ${searchError ? 'show' : ''}`}>
                    {searchError}
                  </p>
                </div>
              </Grid.Column>
            </Grid>

            <Header as='h3'>{t('sider-filters.type')}</Header>
            <ButtonIconGroup flexStart>
              {allSharedCategories.map(category => {
                let active = sharedCategories.includes(category)
                return (
                  <ButtonIcon
                    key={category}
                    title={category}
                    onClick={() =>
                      handleCategoryToggle({ category, service: 'shared' })
                    }
                    active={active}
                    disabled={
                      !active && !result.sharedCategories.includes(category)
                    }
                    square
                    className='button-category'
                  />
                )
              })}
            </ButtonIconGroup>
          </Segment>

          {/* TOURS OPTIONS */}
          {(service === 'tours' || !service) && (
            <Segment basic vertical padded='very'>
              <Header as='h2' dividing>
                {t('sider-filters.tour-details')}
              </Header>
              <Header as='h3'>{t('sider-filters.art')}</Header>
              <ButtonIconGroup flexStart>
                {allToursArtenCategories.map(category => {
                  let active = toursCategories.includes(category)
                  return (
                    <ButtonIcon
                      key={category}
                      title={category}
                      onClick={() =>
                        handleCategoryToggle({ category, service: 'tours' })
                      }
                      active={active}
                      disabled={
                        (serviceGroup && serviceGroup !== 'tours') ||
                        (!active && !result.toursCategories.includes(category))
                      }
                      square
                      className='button-category'
                    />
                  )
                })}
              </ButtonIconGroup>

              <Header as='h3'>{t('sider-filters.category')}</Header>
              <ButtonIconGroup flexStart>
                {allToursKategorienCategories.map(category => {
                  let active = toursCategories.includes(category)
                  return (
                    <ButtonIcon
                      key={category}
                      title={category}
                      onClick={() =>
                        handleCategoryToggle({ category, service: 'tours' })
                      }
                      active={active}
                      disabled={
                        (serviceGroup && serviceGroup !== 'tours') ||
                        (!active && !result.toursCategories.includes(category))
                      }
                      square
                    />
                  )
                })}
              </ButtonIconGroup>

              <Header as='h3'>
                <Trans i18nKey={'sider-filters.title'}>
                  Reisedauer
                  <Header.Subheader>
                    von {durMinLabel} bis {durMaxLabel} Tage
                  </Header.Subheader>
                </Trans>
              </Header>
              <Range
                className='input-range'
                allowCross={false}
                min={1}
                max={18}
                defaultValue={[
                  Number(duration.min) || 1,
                  Number(duration.max) || 18
                ]}
                onChange={([min, max]) => handleDurationSet(min, max)}
                disabled={!!serviceGroup && serviceGroup !== 'tours'}
              />
            </Segment>
          )}

          {/* HOTELS OPTIONS */}
          {(service === 'hotels' || !service) && (
            <Segment basic vertical padded='very'>
              <Header as='h2' dividing>
                {t('sider-filters.hotel-details')}
              </Header>

              <Header as='h3'>
                <Trans i18nKey={'sider-filters.hotel-category'}>
                  Hotelkategorie
                  <Header.Subheader>{starsRange}</Header.Subheader>
                </Trans>
              </Header>
              {starsFiller(stars)}

              <Header as='h3'>{t('sider-filters.accomodation')}</Header>
              <ButtonIconGroup flexStart>
                {allHotelsCategories.map(category => {
                  let active = hotelsCategories.includes(category)
                  return (
                    <ButtonIcon
                      key={category}
                      title={category}
                      onClick={() =>
                        handleCategoryToggle({ category, service: 'hotels' })
                      }
                      active={active}
                      disabled={
                        (serviceGroup && serviceGroup !== 'hotels') ||
                        (!active && !result.hotelsCategories.includes(category))
                      }
                      square
                      className='button-category'
                    />
                  )
                })}
              </ButtonIconGroup>
            </Segment>
          )}

          {/* TRIPS OPTIONS */}
          {(service === 'trips' || !service) && (
            <Segment basic vertical padded='very'>
              <Header as='h2' dividing>
                {t('sider-filters.trip-details')}
              </Header>

              <Header as='h3'>{t('sider-filters.trip')}</Header>
              <ButtonIconGroup flexStart>
                {allTripsCategories.map(category => {
                  let active = tripsCategories.includes(category)
                  return (
                    <ButtonIcon
                      key={category}
                      title={category}
                      onClick={() =>
                        handleCategoryToggle({ category, service: 'trips' })
                      }
                      active={active}
                      disabled={
                        (serviceGroup && serviceGroup !== 'trips') ||
                        (!active && !result.tripsCategories.includes(category))
                      }
                      square
                      className='button-category'
                    />
                  )
                })}
              </ButtonIconGroup>
            </Segment>
          )}
        </div>
        <Responsive maxWidth={computerBreakpoint - 1}>
          <Button
            icon
            fluid
            labelPosition='right'
            color='red'
            style={{ height: 60 }}
            onClick={toggleMobileFilters}
          >
            {result.tours.length + result.hotels.length + result.trips.length}{' '}
            {t('sider-filters.offers')}
            <i className={`icon inverted large ta-close`} />
          </Button>
        </Responsive>
      </div>
    </div>
  )
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapStateToProps = ({
  filters: { options, result, init, place, finderOptions },
  navigation: { mobileFiltersActive }
}) => {
  return {
    options,
    resultHotelsCategories: result.hotelsCategories,
    resultToursCategories: result.toursCategories,
    finderOptions,
    result,
    init,
    place,
    mobileFiltersActive
  }
}

const mapDispatchTopProps = {
  setFilters: filtersActions.setFilters,
  setService: filtersActions.setService,
  resetFilters: filtersActions.resetFilters,
  toggleMobileFilters: navigationActions.toggleMobileFilters
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchTopProps)(SiderFilters)
)
