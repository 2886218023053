import React from 'react'
import { connect } from 'react-redux'
import { Segment, Container, Grid } from 'semantic-ui-react'
import { withTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import Navigation from '../Navigations/Navigation'
import Footer from '../Footer/Footer'
import ScrollToTopButton from '../Common/ScrollToTopButton'
import SiderFilters from '../Filters'
import { filtersActions } from '../Filters/state'
import { parseQueryFilters, scrollToTop, relocalizeLink } from 'utils/helpers'
import { LIVE_URL } from 'utils/constants'

class FiltersLayout extends React.Component {
  componentDidMount = () => {
    const { initData, data, pageContext } = this.props

    // console.log('data', data)
    // console.log('pageContext', pageContext)

    scrollToTop()

    // const options = { country: pageContext.country, ...parseQueryFilters(window.location.pathname, window.location.search) }

    const options = {
      country: pageContext.country,
      region: pageContext.region,
      location: pageContext.location,
      ...parseQueryFilters(window.location.pathname, window.location.search)
    }

    return this.setInitData(data, options, initData)
  }

  componentDidUpdate = prevProps => {
    const { state, initData, data, pageContext, i18n } = this.props

    const prevLang = prevProps.i18n.language
    const prevCountry = prevProps.pageContext.country
    const prevState = prevProps.state && prevProps.key

    const currLang = i18n.language
    const currCountry = pageContext.country
    const currState = state && state.key

    if (
      prevCountry !== currCountry ||
      prevLang !== currLang ||
      prevState !== currState
    ) {
      // const options = { country: pageContext.country, ...parseQueryFilters(window.location.search) }
      const options = {
        country: pageContext.country,
        region: pageContext.region,
        location: pageContext.location,
        ...parseQueryFilters(window.location.pathname, window.location.search)
      }

      return this.setInitData(data, options, initData)
    }
  }

  setInitData = (data, options, initData) => {
    const hotels = data.allFirestoreHotel ? data.allFirestoreHotel.edges : []
    const tours = data.allFirestoreTour ? data.allFirestoreTour.edges : []
    const trips = data.allFirestoreTrip ? data.allFirestoreTrip.edges : []

    // console.log({ hotels, tours, trips })

    const countries = data.allFirestoreCountry.edges
    const regions = data.allFirestoreRegion.edges
    const locations = data.allFirestoreLocation.edges

    const categories = data.allFirestoreCategory.edges
    const country = data.firestoreCountry ? data.firestoreCountry.title : ''

    initData({
      init: {
        hotels,
        tours,
        countries,
        regions,
        locations,
        categories,
        trips,
        country
      },
      options
    })
  }

  render() {
    const { children, data, pageContext, i18n } = this.props

    const countries = data.allFirestoreCountry.edges
    const categories = data.allFirestoreCategory.edges

    const hotels = data.allFirestoreHotel ? data.allFirestoreHotel.edges : []
    const trips = data.allFirestoreTrip ? data.allFirestoreTrip.edges : []
    const tours = data.allFirestoreTour ? data.allFirestoreTour.edges : []

    const lang = i18n.language
    const otherLang = lang === 'de' ? 'fr' : 'de'
    const alternateHref = LIVE_URL + relocalizeLink(otherLang, pageContext)

    return (
      <div>
        <Helmet>
          <html lang={lang} />
          <link rel='alternate' hreflang={otherLang} href={alternateHref} />
        </Helmet>

        <Navigation
          pageContext={pageContext}
          countries={countries}
          categories={categories}
        />

        <div className='content-wrapper'>
          <Segment basic vertical className='lightgrey' inverted>
            <Container>
              <Grid>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={5}
                  largeScreen={4}
                  widescreen={4}
                >
                  <SiderFilters
                    tours={tours}
                    trips={trips}
                    hotels={hotels}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={11}
                  largeScreen={12}
                  widescreen={12}
                  className='content-wrapper'
                >
                  {children}
                </Grid.Column>
              </Grid>
            </Container>
          </Segment>
        </div>

        <Footer pageContext={pageContext} />

        <ScrollToTopButton />
      </div>
    )
  }
}

/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchTopProps = {
  initData: filtersActions.initData,
  setFilters: filtersActions.setFilters,
  removeItems: filtersActions.removeItems
}

export default withTranslation()(
  connect(null, mapDispatchTopProps)(FiltersLayout)
)
